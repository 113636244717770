.barre-de-recherche-button{
    width: 100%; 
    height: 100%; 
    margin-left: 0; 
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;

    background-color: #F9BA14;
    border-color: #F9BA14;
    font-weight: "bold";
}

.barre-de-recherche-button:hover{
    background-color: #344B7B;
    border-color: #344B7B;
}