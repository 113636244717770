nav{
    background-color: #344B7B;
}

.dl-desktop-navbar-link, .dl-desktop-navbar-link:hover, .dl-desktop-navbar-link:focus, .dl-desktop-navbar-menu-title, .dl-desktop-navbar-menu-title:hover, .dl-desktop-navbar-menu-title:focus {
    color: white;
    cursor: pointer;
    text-decoration: none;
    padding: 0 20px 0 !important;
    font-weight: 500;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    font-size: 16px;
    height: 100%;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.dl-desktop-navbar-link-icon {
    fill: #fff;
    margin: 0 7px 0 0;
}

a.dl-desktop-navbar-b2b-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0 14px;
    height: 40px;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    border-radius: 4px;
    text-decoration: none;
    -webkit-transition: color 150ms, background-color 150ms;
    transition: color 150ms, background-color 150ms;
    border: 1px solid #fff;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

a.dl-desktop-navbar-b2b-btn svg {
    fill: currentColor;
    margin-right: 7px;
}

.side-item-desktop {
    display: none;
}

@media screen and (max-width: 767px) {
    .side-item {
        display: none;
    }

    .side-item-desktop {
        display: block;
    }
}