audio, canvas, iframe, img, svg, video {
    vertical-align: middle;
}

.cookie_banner {
    position: fixed;
    z-index: 9999;
    bottom: 35px;
    right: 35px;
    height: 600px;
    width: 400px;
    border-radius: 4px;
    padding: 10px 20px 10px 5px;
    /*color: #606060;*/
    color: white;
    /*background-color: #f1f4f5;*/
    background-color: blue;
    font: 400 11px/15px Poppins,sans-serif;
    display: flex;
    flex-direction: row;
}

.consultation-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    /*background-color: hsla(0,0%,100%,.8)*/
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    padding-bottom: 50px;
    color: #fff;

    min-height: 700px;
}

.consultation-container .backgroundvideo {
    position: relative;
    /*top: 0;
    left: 0;
    right: 0;
    bottom: 0;*/
    /*height: 100%;*/
    max-width: 100%;
    overflow: hidden;
    margin: 0 auto;
    /*z-index: -1;*/
    width:60%;
}

@media only screen and (max-width: 600px) {
    .consultation-container .backgroundvideo{
        width: 100%;
    }
  }

.remote_video {
    position: relative;
    z-index: 999;
    /*height: 200px;
    width: 200px;
    border-radius: 4px;*/
    /*padding: 2px;*/
    /*color: #606060;*/
    color: white;
    /*background-color: #f1f4f5;*/
    /*background-color: white;*/
    font: 400 11px/15px Poppins,sans-serif;
    /*display: flex;
    flex-direction: row;*/

    width: 100%;
    /*right: 125px;*/
    text-align: end;
}

.remote_video video{
    width: 25%;
    overflow: hidden;
    border: 2px white solid;
}

@media only screen and (max-width: 600px) {
    .remote_video video{
        width: 30%;
    }
  }

audio, video {
    display: inline-block;
}

.tlc-init h2 {
    margin: 5vmin 0;
    color: #64c9c3;
    text-align: center;
}

.consultation-settings-container{
    max-width: 100%;
    height: 100%;

    background-color: hsla(0,0%,100%,.8);
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    z-index: 998;
    overflow: auto;
}

.consultation-remote-video-container{
    max-width: 100%;
    height: 100%;

    position: absolute;
    /*top:0;
    left: 0;
    right: 0;*/
    z-index: 997;
    padding: 1rem;
    width: 60%;
}

@media only screen and (max-width: 600px) {
    .consultation-remote-video-container{
        width: 100%
    }
  }

.settings-container{
    margin: 0 auto;
    max-width: 45%;
    position: relative;
    left: 0;
    right: 0;
    z-index: 999;
}

@media only screen and (max-width: 600px) {
    .settings-container{
        margin: 0 auto;
        max-width: 95%;
    }
  }

  @media only screen and (max-width: 600px) {
    .consultation-settings-container{
        background-color: rgba(0, 0, 0,0.5);
        overflow: auto;
    }
  }

  @media only screen and (max-width: 600px) {
    .settings-container h3{
        color: white !important;
    }
  }

.consultation-message{
    margin-top: 8rem;
    text-align: center; 
    color: #344B7B; 
    font-size: 1.4rem;
}

@media only screen and (max-width: 600px) {
    .consultation-message{
        color: white;
        font-size: 1rem;
    }
  }

  .consultation-loader svg{
      color: #344B7B;
      fill: #344B7B;
  }

  @media only screen and (max-width: 600px) {
    .consultation-loader svg{
        color: white;
        fill: white;
    }
  }

  .consultation-bouton-terminer{
    position: absolute;
    width: 135px;
    top: 20px;
    left: 0;
    padding: 0 10px;
    background-color: red;
    color: white;
    cursor: pointer;
}