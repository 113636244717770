@media (min-width: 950px){
    .searchbar-input {
        font-size: 16px;
    }
}

.sous-titre{
    color: rgba(255,255,255,.5);
}

.location-search-input {
    width: 330px;
    height: 50px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.location-search-input:focus {

}