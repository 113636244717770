p {
    margin: 0 0 10px;
}

.dl-legal-content-html {
    padding: 3rem 2rem;
    font-size: 14px;
}

.dl-legal-content-html h1 {
    font-size: 20px;
}

.dl-legal-content-html h3 {
    font-size: 14px;
    font-weight: bold;
}

.dl-legal-content-html h1, .dl-legal-content-html h2, .dl-legal-content-html h3 {
    color: #224358;
    margin: 0 0 8px;
    /*font-weight: 500;*/
}

@media (min-width: 768px){
    .dl-legal-content-html h1, .dl-legal-content-html h2, .dl-legal-content-html h3 {
        margin: 0 0 16px;
    }    
}

@media (min-width: 768px){
    .dl-legal-content-html h1 {
        font-size: 26px;
    }
}


.dl-legal-content-html ul{
    margin: 0;
}