.testimonial-item{
    color: black;
}

.testimonials .testimonial-item img {
    max-width: 9rem;
    box-shadow: 0px 5px 5px 0px #adb5bd;
}

.testimonial-item:hover{
    cursor: pointer;
}

h2, h5{
    color: #344B7B;
}

.ReactModal__Content {
    width: 60%;
    position: relative;
}

.ReactModal__Content button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    outline: none;
    padding: 5px 12px;
    border-radius: 100%;
    background-color: rgb(213, 52, 52);
    color: white;
    font-weight: bold;
}

.react-responsive-modal-modal {
    padding: 0 !important;
}

@media screen and (max-width: 767px) {
    .ReactModal__Content {
        width: 85%;
    }
}

@media screen and (max-width: 640px) {
    .ReactModal__Content {
        width: 95%;
    }
}