.modal-content {
  border-radius: 0;
  border: none;
}

.bg-image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/img/bg4.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* -webkit-filter: blur(5px); */
  /* -filter: blur(5px); */
}

.modal-content .blur-bg {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1.2rem;
  z-index: 99;
  color: black !important;
}

.react-responsive-modal-closeButton {
  z-index: 9999;
}

.react-responsive-modal-closeButton svg {
  fill: black;
}

@media screen and (max-width: 767px) {
  .section {
    width: 100% !important;
  }
}
