.setting-item{
    background-color: white;
    min-height: 48px;
    margin-bottom: 15px;
}

.setting-header{
    display: grid;
    grid-template-columns: auto 30px;
    min-height: 64px;
    padding: 0 24px 0 24px;
    /*margin: 12px 0;*/
}

.setting-body{
    padding: 0 24px 24px 24px;
    /*margin: 12px 0;*/
}

.setting-header .setting-header-title{
    display: flex;
    align-items: center;
}

.setting-header .setting-header-icon{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}