html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #f7f7f7;*/

  background-image: url('./assets/img/bg-connexion.png');
  height: 100%; 
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.action-button, .search-button{
  background-color: #F9BA14 !important;
  color: white !important;
  border-color: #F9BA14 !important;
  font-weight: bold;
}

.action-button:hover, .search-button:hover{
  background-color: #344B7B !important;
  color: white !important;
  border-color: #344B7B !important;
}

.action-button.disabled, .action-button:disabled, .search-button.disabled, .search-button:disabled {
  background-color: #F9BA14 !important;
  color: white !important;
  border-color: #F9BA14 !important;
}

@media only screen and (max-width: 600px) {
  .search-button{
      width: 90px !important;
  }
}

@media only screen and (max-width: 600px) {
  .action-button{
      width: 100% !important;
  }
}

.navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
}

.dropdown-menu{
  z-index: 999999;
}

.bleu{
  background-color: #344B7B;
}

.jaune{
  background-color: #F9BA14;
}

.gris{
  background-color: #C6C6C5;
}

.message{
  margin-top: 2em;
  font-size: 0.9em;
  color: #606060;
}

.btn-contact-medecin {
  color: #fff;
  background-color: #344B7B;
  border-color: #344B7B;
  width: 220px;
  height: 50px;
  font-weight: bold;
} 

.btn-contact-medecin:hover, .btn-contact-medecin:active {
  color: #fff;
  background-color: #162d5d;
  border-color: #162d5d;
}

.btn-contact-medecin.focus, .btn-contact-medecin:focus {
  color: #fff;
  background-color: #162d5d;
  border-color: #162d5d;
  box-shadow: 0 0 0 0.2rem rgb(52 75 123 / 50%);
}

.custom-background {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('./assets/img/hopital.jpeg');
  /* background: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fthumbs.dreamstime.com%2Fb%2Fempty-hospital-hall-interior-clinic-waiting-room-horizontal-banner-empty-hospital-hall-interior-clinic-waiting-room-horizontal-114188455.jpg&f=1&nofb=1&ipt=621d99d07b548f80f1686f7a80f17921a996c8e8a22d046f7ca0803c03e9ffa4&ipo=images'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}