.identification-background{
    background-image: url('./assets/img/bg-connexion-1.png');
}

.dl-layout-body {
    margin: 14px;
}

/*@media (min-width: 478px){
    .dl-layout-body-size-small.dl-layout-body-bottom {
        padding: 0 0 28px;
    }
}*/

@media (min-width: 478px){
    .dl-layout-body-size-small {
        width: 450px;
        margin: 14px auto;
    }
}

.dl-card-padding {
    padding: 14px;
}

.dl-card-margin {
    margin: 14px;
}

.dl-card-bg-white {
    background-color: #fff;
}

.dl-card {
    position: relative;
    border-radius: 4px;
    background: #fff;
    -webkit-transition: box-shadow 300ms;
    transition: box-shadow 300ms;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    text-decoration: none;
    color: #435f71;
}

.dl-card-content {
    min-height: 50%;
}

.dl-text {
    margin: 0;
    font-weight: 400;
    color: #435f71;
}

.dl-text-center {
    text-align: center;
}

.dl-text-margin {
    margin: 14px 0;
}

.dl-text-body-subtitle {
    font-size: 13px;
    line-height: 1.7;
    font-weight: 700;
}

.dl-input-hint {
    padding: 8px 0 0;
    text-align: left;
}

@media (min-width: 768px){
    .dl-text-body-subtitle {
        font-size: 13px;
    }
}

@media (min-width: 768px){    
    .dl-text-body {
        font-size: 14px;
    }
}

@media (min-width: 768px){
    .dl-text-title-sub-regular, .dl-menu-header-title, .dl-menu-header {
        font-size: 16px;
    }
}

.dl-button{
    font-size: 13px;
    color: #435f71;
    height: 44px;
    border: 0;
    outline: none;
    box-shadow: none;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    text-decoration: none !important;
    text-transform: uppercase;
    margin: 0;
    display: inline-block;
    padding: 0 14px;
    -webkit-transition: opacity 500ms, color 200ms, background-color 200ms;
    transition: opacity 500ms, color 200ms, background-color 200ms;
    background-color: transparent;
    cursor: pointer;
}

.form-group{
    margin-top: 14px;
    margin-bottom: 10px;
}

#lienMotDePasse{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); 
    user-select: none; 
    cursor: pointer;
}

.txt-center {
    text-align: center !important;
}

.dl-button-default-real-link {
    border-bottom: solid 1px;
    padding: 0;
    font-size: 12px;
}

a.dl-button {
    line-height: 44px;
}

.dl-button-primary {
    background-color: #fdc400;
    border-color: #fdc400;
    color: #224358;
    font-size: 14px;
}

.dl-toggleable-form {
    overflow: hidden;
    max-height: 0;
}

.dl-toggleable-form-button, .dl-toggleable-form {
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
}

hr {
    border-top: 1px solid #cfd8dc;
    box-sizing: content-box;
}

.dl-divider {
    position: relative;
    height: 1px;
    margin: -1px 0 0;
    background-color: rgba(67,95,113,0.2);
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.dl-margin-y {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
}

label {
    font-weight: 400;
}

.dl-checkbox-label-text {
    color: #435f71;
    font-size: 14px;
}

lien-connexion {
    font-size: 14px;
}

lien-connexion:hover {
    color: #0056b3;
    text-decoration: none;
}