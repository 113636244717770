.prestation-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1em;
    cursor: pointer;
}

.prestation-container .col{
    margin: 1em 0 !important;
}

.prestation-container h1 {
    margin: 0;
    padding: 20px 0;
    font-size: 14px;
    line-height: 22px;
    font-family: "Roboto", arial, sans-serif;
    font-weight: 700;
    text-align: center;
}

.prestation-card{
    width: 100% !important;
    text-align: center;
}

.prestation-card:hover{
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.prestation-card img{
    /*width: 30% !important;*/
    height: 5em !important;
}

.prestation-card h5{
    margin-top: 15px;
}

