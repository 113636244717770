.header_search {
    background-color: #C6C6C5;
    padding: 100px 0 14px;
    width: 100%;
    height: 300px;
    /*border-top: solid 1px #0596de;*/
}

.results h1, .results h2 {
    margin: 0;
    padding: 20px 0;
    font-size: 14px;
    line-height: 22px;
    font-family: "Roboto", arial, sans-serif;
    font-weight: 700;
    text-align: center;
}

.search-results {
    grid-area: searchcard;
    margin-top: var(--filter-bar);
    width: var(--card-width);
    --gap: 10px;
    --recap-height: calc(var(--header-bar) + 10px);
    --card-width: 760px;
    --map-width: 25vw;
    --map-max-width: 400px;
}


@media only screen and (max-width: 600px) {
    .search-results {
        grid-area: searchcard;
        margin-top: var(--filter-bar);
        width: var(--card-width);
        --gap: 10px;
        --recap-height: calc(var(--header-bar) + 10px);
        --card-width: 95%;
        --map-width: 25vw;
        --map-max-width: 400px;
    }
  }

.search{
    /*background-color: #f7f7f7;*/
    display: flex;
    justify-content: center;

}

@media only screen and (max-width: 600px) {
    .search-button{
        width: 90px !important;
    }

    .header_search {
        padding: 40px 0 14px;
    }
  }