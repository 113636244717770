body {
  font-family: Helvetica, sans-serif;
}

.react-autosuggest__container {
  flex: auto;
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 50px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  max-height: 390px;
  overflow: auto;
  border: 1px solid #aaa;
  background-color: #fff;
  color: black;
  text-align: initial;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion > div{
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.barre{
    border-top: 1px solid rgba(67,95,113,0.3);
    width: 100%;
}

.searchbar-result-profile-avatar {
    -webkit-flex-basis: 40px;
    -ms-flex-preferred-size: 40px;
    flex-basis: 40px;
    min-width: 40px;
    height: 40px;
}

.searchbar-result-profile-details {
    padding-left: 14px;
}

.searchbar-result-profile-avatar{
    flex-basis: 40px;
    width: 40px;
    height: 40px;
}

.searchbar-result-profile-avatar img {
    width: 100%;
    height: 100%;
}

.dl-image-rounded {
    border-radius: 50%;
}

.searchbar-result-profile-subtitle {
    font-size: 12px;
    color: rgba(67,95,113,0.6); 
    line-height: 14px;
}