.admin-container{
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    background-color: white;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 0 24px 24px 24px;
}

.admin-element{
    display: grid;
    grid-template-columns: auto auto;
    padding: 30px 15px 5px 15px;
    margin: 0px 20px;
    border-bottom: 1px solid #F9BA14;
    cursor: pointer;
}

.admin-container nav{
    background-color: white;
}

.admin-container ul{
    margin: 3rem auto 0 auto;
    background-color: white;
    width: fit-content;
}

.add-element{
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    
    padding: 12px;
    text-align: right;
    cursor: pointer;
}

.add-element svg{
    height: 0.9rem;
}

.admin-filter{
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    /*background-color: white;*/
    margin-top: 15px;
    margin-bottom: 15px;
    /*padding: 0 24px 24px 24px;*/
    text-align: center;
}

.admin-menu-retour{
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    cursor: pointer;
}