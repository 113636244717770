.search-card--tlc {
  min-height: 0;
  padding-bottom: 0;
  padding-right: 0;
}

.search-card {
  position: relative;
  margin-bottom: var(--gap);
  background-color: #fff;
  transition: all .3s cubic-bezier(.25,.8,.25,1);
  border-radius: 5px;
}

.searchbar-card-profile-avatar {
  min-width: 100px; 
  height: 100px; 
  justify-content: center; 
  text-align: center;
  height: 100%;
  display: flex;
  /*margin-top: 10px;*/
}

.search-card--tlc .action {
  background-color: #E5E5E5;
  border-radius: 0 5px 5px 0;
  color: #189338;
  text-align: center;
  /*padding: 10px;*/
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.action:hover {
  background-color: #C6C6C5;
  cursor: pointer;
}

.search-card__infos{
  /*margin-top: 10px;*/
  margin-bottom: 10px;
  padding-left: 10px;
}

.action .button, .avatar {
  position: relative;
  border-radius: 50%;
  min-height: 25px;
  min-width: 25px;
  /*height: 100%;*/
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.action .button-size--big, .avatar-size--big {
  /*height: 100px;*/
  width: 100px;
}

.action .button__online, .avatar__online {
  position: absolute;
  bottom: 10px;
  right: 3px;
  background-color: #86f1b0;
  border-radius: 50%;
  height: 15px;
  width: 15px;
}

.search-card--tlc .action svg {
  fill: #fff;
  height: 41px;
  width: 41px;
  position: relative;
}

.search-card--tlc .action .closing {
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  grid-area: closing;
}

.search-card--tlc .action .closing span {
  font-weight: 500;
  font-size: 15px;
}

.search-card--tlc .action .waiting {
  text-align: center;
  grid-area: waiting;
  margin-top: -15px;
}

.search-card--tlc .action .waiting span {
  font-weight: 500;
  font-size: 12px;
  margin-left: 8px;
}

.search-card--tlc .action .button {
  width: auto;
  /*margin: 8px auto 0;*/
}

.search-card__infos .prat__name {
  font-size: 13px;
  font-weight: bold;
  /*color: #64c9c3;*/
  color: #344B7B;
  position: relative;
  font-family: Poppins;
  word-break: break-all;
  cursor: pointer;
}

.search-card__infos .prat__spe {
  font-size: 12px;
}

@media screen and (min-width: 768px){
  .killondesk {
    display: none!important;
  }
}

.search-card__infos .prat__address, .search-card__infos .prat__zipcity {
  color: #b7b7b7;
  font-size: 12px;
  text-transform: lowercase;
  font-weight: bold
}

.search-card__infos .prat__secu {
  color: #606060;
  font-style: italic;
  font-size: 13px;
}

.search-card__infos .prat__rdv-button {
  margin-top: 25px;
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 500;
}