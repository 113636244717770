.account-container{
    margin: 0 auto;
    max-width: 800px;
}

.account-patient{
    display: grid;
    grid-template-columns: 60px auto auto;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #F9BA14;
    cursor: pointer;
}

.account-patient .initial{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: #F9BA14;
    color: white;
    border-radius: 50%;
}

.add-patient{
    margin-top: 12px;
    text-align: right;
    cursor: pointer;
}

.account-credential{
    display: grid;
    grid-template-columns: auto auto;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid green;
}

.last{
    border-bottom:0;
}